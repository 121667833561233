




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    InsCmsGallery: () => import('@/components/business/cms/InsCmsGallery.vue'),
    InsCurrentTab0: () => import('@/components/business/cms/InsCurrentTab0.vue'),
    InsCurrentTab1: () => import('@/components/business/cms/InsCurrentTab1.vue')
  }
})
export default class InsCmsCat3 extends Vue {
  @Prop() private cmsCategoryChilds!: object;
  buttonNames: object[] = [];
  currentTab: string = '0';
  catSonData: object[] = [];

  // 獲取按鈕
  getButtonData () {
    if (this.cmsCategoryChilds[1].Children && this.cmsCategoryChilds[1].Children.length > 0) {
      this.cmsCategoryChilds[1].Children.forEach(item => {
        this.buttonNames.push(item.Name);
      });
      this.catSonData = this.cmsCategoryChilds[1].Children;
    }
    console.log(this.buttonNames, '按钮数据');
  }
  get isMobile () {
    return this.$store.state.isMobile;
  }
  get id () {
    return this.$route.params.id;
  }
  mounted () {
    this.getButtonData();
  }
  @Watch('id', { deep: true })
  onKeyChange () {
    this.buttonNames = [];
    this.catSonData = [];
    this.getButtonData();
    console.log(this.cmsCategoryChilds, '傳過來的cmsCategoryChilds');
  }
}
